<template>
  <div class="pa-3">
    <div class="d-flex justify-center">
      <div class="col-sm-12 col-md-5 col-lg-3">
        <h2 v-text="internalName"></h2>
      </div>
    </div>
    <div class="d-flex justify-center" v-if="places.length">
      <div class="col-sm-12 col-md-5 col-lg-3">
        <div class="d-flex flex-column">
          <div class="pb-3" v-for="(place, i) in places" :key="i">
            <v-card elevation="3" class="py-2 rounded-lg" min-height="64">
              <div class="d-flex mx-3">
                <div>
                  <p
                    class="my-auto font-weight-bold text-uppercase"
                    v-text="place.name"
                  ></p>
                  <a
                    v-if="place.urlWebsite"
                    :href="place.urlWebsite"
                    class="btn align-center"
                    target="_blank"
                    >Ir a sitio web</a
                  >
                </div>
                <div class="d-flex align-center ml-auto flex-item">
                  <a
                    v-if="place.urlGoogleMap"
                    :href="place.urlGoogleMap"
                    class="btn d-flex align-center"
                    target="_blank"
                    ><img src="@/assets/icons/mapas-google.svg" width="35"
                  /></a>
                  <a
                    v-if="place.urlWazeMap"
                    :href="place.urlWazeMap"
                    class="btn d-flex align-center"
                    target="_blank"
                  >
                    <img src="@/assets/icons/waze.svg" width="42"
                  /></a>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.border-1 {
  border: 1px solid;
}
</style>

<script>
import loaderMixin from "@/mixins/loaderMixin";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  name: "InterestPlaces",
  mixins: [loaderMixin, notificationMixin],
  data() {
    return {
      places: [],
      showAlertOffline: false,
    };
  },
  methods: {
    redirectToFair() {
      this.$router.push({ name: "fair" });
    },
    getPlaces() {
      this.$axiosApiMobile
        .get(`/interest-places?fairId=${this.fair.id}`)
        .then((res) => {
          this.places = res.data;
          this.hideLoader();
        })
        .catch((error) => {
          this.errorHandler(error?.response?.status);
          this.hideLoader();
        });
    },
  },
  computed: {
    fair() {
      return this.$store.getters["fairs/fair"];
    },
    internalName() {
      return (
        this.fair?.app_modules?.filter((item) => item.modules == "INT")[0]
          ?.internalName ?? "Sitios de Interés"
      );
    },
  },
  created() {
    this.getPlaces();
    this.showLoader();
  },
};
</script>
